import styled from "styled-components"

// This file is for global styled component.

/**
 * Based on the alert bootstrap component
 * It receives a level prop that can be one of success, info, warning and danger
 *
 * @function
 * @property {String} level - level for display, determines background colours, etc.
 *
 * @example
 *
 * Usage:
 *
 * import { Alert } from "../Globals"
 *
 * function MyComponent() {
 *  return (
 *    <Alert level="success">My message</Alert>
 *  )
 * }
 *
 * @notes
 *
 * This component must be wrapped with the ThemeProvider from
 * styled-components. The ThemeProvider is at the top level of the App
 * and AppProvider components, but you may need to add it if you are
 * using Alert in a different setting.
 *
 */
export const Alert = styled.div`
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;

  ${props => {
    if (props.level === "success") {
      return `
        color: ${props.theme.colors.stateSuccessText};
        background-color: ${props.theme.colors.stateSuccessBg};
        border-color: ${props.theme.colors.stateSuccessBorder};
      `
    } else if (props.level === "info") {
      return `
        color: ${props.theme.colors.stateInfoText};
        background-color: ${props.theme.colors.stateInfoBg};
        border-color: ${props.theme.colors.stateInfoBorder};
      `
    } else if (props.level === "warning") {
      return `
        color: ${props.theme.colors.stateWarningText};
        background-color: ${props.theme.colors.stateWarningBg};
        border-color: ${props.theme.colors.stateWarningBorder};
      `
    } else if (props.level === "danger") {
      return `
        color: ${props.theme.colors.stateDangerText};
        background-color: ${props.theme.colors.stateDangerBg};
        border-color: ${props.theme.colors.stateDangerBorder};

        & ul {
          margin: 0;
          padding: 0;
          padding-left: 15px;
        }
      `
    }

    return `
      border-color: transparent;
    `
  }};
`

export const AlertLink = styled.a`
  font-weight: 700;
  ${props => {
    if (props.level === "success") {
      // not implemented yet
      return `

      `
    } else if (props.level === "info") {
      // not implemented yet
      return `

      `
    } else if (props.level === "warning") {
      // not implemented yet
      return `

      `
    } else if (props.level === "danger") {
      return `
        color: #843534;
        &:hover {
          color: #843534;
        }
      `
    }

    return `
    `
  }};
`

export const MutedText = styled.span`
  color: #777777;

  ${props => (props.small ? "font-size: 12px;" : "")}
`

export const HelpText = styled.small`
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
`

export const Small = styled.div`
  font-size: 12px;
`

/**
 * this is not for input labels. It's for bootstrap 3 labels
 * @see https://getbootstrap.com/docs/3.3/components/#labels
 */
export const Label = styled.span`
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;

  ${props => {
    if (props.appearance === "primary") {
      return `
        background-color: #337ab7;
      `
    }
    if (props.appearance === "success") {
      return `
        background-color: #5cb85c;
      `
    }
    if (props.appearance === "warning") {
      return `
        background-color: #f0ad4e;
      `
    }
    if (props.appearance === "danger") {
      return `
        background-color: #d9534f;
      `
    }
    if (props.appearance === "in-progress") {
      return `
        background-color: #8C741D
      `
    }
  }}
`

export const BlankSlate = styled.div`
  position: relative;
  padding: 30px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);

  ${props => {
    let styles = ""

    if (props.clean) {
      styles += `
        background: none;
        border: 0;
        box-shadow: none;
      `
    }

    return styles
  }}

  & p {
    color: #999;
  }
`

// Notices are normally shown inside Panels (see Attachments and Schedule panel on the jobs page)
export const Notice = styled.div`
  background: #fafafa;
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-align: center;

  & svg {
    color: #ccc;
  }
`
